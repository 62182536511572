export const MenuListArray2 = [
  {
    title: "Home",
    to: "/",
  },
  {
    title: "Products",
    to: "./products",
  },
  {
    title: "About us",
    to: "./about-us",
  },

  {
    title: "Contact Us",
    to: "./contact-us",
  },
];
