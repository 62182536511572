import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
//images

//import logo from './../assets/images/logo.png';

//function slideDown(el) {
//	console.log(el);
//	const elem = document.getElementById(el);
//	console.log(elem);
//	document.getElementById("myH1").style.color = "red";
//	elem.style.transition = "all 2s ease-in-out";
//	//elem.style.height = "400px";
//}

function Footer({ footerChange, logoImage }) {
  //const [open, setOpen] = useState(false);
  const [accordBtn, setAccordBtn] = useState();
  return (
    <>
      <footer className={"site-footer"}>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-6 col-lg-12 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="widget widget_about">
                  <div className="footer-logo logo-white">
                    <Link to={"/"}>
                      <img src={logoImage} alt="logo-bnt" />
                    </Link>
                  </div>
                  <p className="text">
                    Bourne technicals is a Trading Company which deals with
                    several Pneumatic and Hydraulic products
                  </p>
                  <div className="dz-social-icon style-1">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/bournetechnicals/"
                          target="_blank"
                        >
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="fa-brands fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="widget widget_getintuch">
                  <h5 className="footer-title">Get in Touch With Us</h5>
                  <ul>
                    <li>
                      <i className="flaticon-placeholder"></i>
                      <span>INDIA</span>
                    </li>
                    <li>
                      <i className="flaticon-phone"></i>
                      <span>
                        +91 7356 384578
                        <br />
                        +91 7356 384576
                      </span>
                    </li>
                    <li>
                      <i className="flaticon-email"></i>
                      <span>
                        sales@bournetechnicals.com
                        <br />
                        rfq@bournetechnicals.com
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row fb-inner">
              <div className="col-lg-6 col-md-12 text-start">
                <p className="copyright-text">
                  Bourne technicals - © 2022 All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
