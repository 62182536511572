import React, { useState } from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

//Images
import bgwave from "./../../assets/images/background/waveelement.png";
import media1 from "./../../assets/images/banner/banner-media.png";

//import { EffectFade, Autoplay , Parallax, Pagination} from "swiper";

import SwiperCore, {
  EffectFade,
  Autoplay,
  FreeMode,
  Parallax,
  Thumbs,
  Pagination,
} from "swiper";
SwiperCore.use([Parallax, Thumbs, FreeMode, Autoplay, Pagination, EffectFade]);

const homeData1 = [
  {
    image: media1,
    title: "Bourne Technicals",
    datatitle: "We Make Industry work better",
  },
];

export default function HomeMainSlider() {
  return (
    <>
      <Swiper className="swiper-container main-swiper ">
        {homeData1.map((data, index) => (
          <SwiperSlide
            className="bg-blue"
            key={index}
            style={{ backgroundImage: "url(" + bgwave + ")" }}
          >
            <div className="container">
              <div className="banner-content">
                <div className="row">
                  <div className="col-md-12 d-view">
                    <div className="swiper-content">
                      <div className="content-info d-content">
                        <h6 className="sub-title" data-swiper-parallax="-10">
                          {data.datatitle}
                        </h6>
                        <h1 className="title mb-0" data-swiper-parallax="-20">
                          {data.title}
                        </h1>
                        <ul className="dz-tags" data-swiper-parallax="-30">
                          <li>
                            <a>
                              Oil & Gas | Power Gas | Automation & Control |
                              General Trading & Sourcing
                            </a>
                          </li>
                        </ul>

                        <div className="content-btn" data-swiper-parallax="-60">
                          <Link
                            className="btn btn-primary btnhover"
                            to={"contact-us"}
                          >
                            Contact Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
